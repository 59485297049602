/** @format */

import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import styled from "styled-components";

function FooterComp() {
	useEffect(() => {
		Aos.init({duration: 2000});
	}, []);
	const faceBookLink = () => {
		window.open("https://www.facebook.com/InfiniteAppsWebDesign");
	};

	// eslint-disable-next-line
	const instagramLink = () => {
		window.open("https://www.facebook.com/InfiniteAppsWebDesign");
	};

	return (
		<FooterWrapper className='main-footer'>
			<div className='container-fluid col-10 mx-auto'>
				<div className='row mx-auto'>
					{/* Column1 */}
					<div className='col-lg-4 col-md-6 col-sm-10   my-2'>
						<h5>Infinite-Apps</h5>
						<h6 className='list-unstyled mx-auto'>
							<ul style={{listStyle: "none"}}>
								<li>
									Phone:{" "}
									<Link to='#' onClick={() => window.open("tel:9099914386")}>
										(909) 991-4386
									</Link>{" "}
								</li>
								<li>PO Box 322 </li>
								<li> Crestline, CA 92325 USA</li>
								<li>Email: ahmed.abdelrazak@infinite-apps.com</li>
							</ul>
						</h6>
					</div>
					{/* Column2 */}
					<div className='col-lg-4 col-md-6 col-sm-10 mx-auto my-2'>
						<h5>Quick Link:</h5>
						<ul className='list-unstyled'>
							<li style={{fontWeight: "bold"}}>
								<Link to='#' onClick={faceBookLink}>
									<i
										style={{
											color: "lightblue",
											fontSize: "15px",
											fontWeight: "bold",
										}}
										className='fab fa-facebook-f mr-2'
									></i>{" "}
									<span style={{cursor: "pointer", fontWeight: "bold"}}>
										Infinite-Apps Facebook
									</span>
								</Link>{" "}
							</li>

							<li
								style={{fontWeight: "bold"}}
								onClick={() => {
									window.scrollTo({top: 0, behavior: "smooth"});
								}}
							>
								<Link to='/application-builder/ecommerce-clothing-fashion'>
									<i
										style={{
											color: "lightgrey",
											fontSize: "12px",
											fontWeight: "bold",
										}}
										className='fas fa-shopping-cart mr-1'
									></i>{" "}
									<span style={{cursor: "pointer", fontWeight: "bold"}}>
										Consumer Products
									</span>
								</Link>
							</li>
							<li
								style={{fontWeight: "bold"}}
								onClick={() => {
									window.scrollTo({top: 0, behavior: "smooth"});
								}}
							>
								<Link to='/application-builder/ecommerce-gifts'>
									<i
										style={{
											color: "lightgrey",
											fontSize: "12px",
											fontWeight: "bold",
										}}
										className='fa-solid fa-gift mr-1'
									></i>{" "}
									<span style={{cursor: "pointer", fontWeight: "bold"}}>
										Gift Store
									</span>
								</Link>
							</li>
							<li
								style={{fontWeight: "bold"}}
								onClick={() => {
									window.scrollTo({top: 0, behavior: "smooth"});
								}}
							>
								<Link to='/scheduling-software-builder'>
									<i
										style={{
											color: "lightgrey",
											fontSize: "12px",
											fontWeight: "bold",
										}}
										className='fa-solid fa-calendar mr-1'
									></i>{" "}
									<span style={{cursor: "pointer", fontWeight: "bold"}}>
										Scheduling Systems
									</span>
								</Link>
							</li>
						</ul>
					</div>
					{/* Column3 */}
					<div className='col-lg-4 col-md-6 col-sm-10 mx-auto my-2'>
						<h5>Contact Us:</h5>
						<ul className='list-unstyled'>
							<li style={{fontWeight: "bold"}}>
								{" "}
								<Link
									to='/contact'
									onClick={() => {
										window.scrollTo({top: 0, behavior: "smooth"});
									}}
								>
									General Query
								</Link>{" "}
							</li>
							<li style={{fontWeight: "bold"}}>
								{" "}
								<Link
									to='/pricing/creating-websites'
									onClick={() => {
										window.scrollTo({top: 0, behavior: "smooth"});
									}}
								>
									Shcedule a meeting to start your Web App
								</Link>{" "}
							</li>
							<li>Email: ahmed.abdelrazak@infinite-apps.com</li>
						</ul>
					</div>
				</div>
				<hr />
				<div className='row'>
					<p className='col-sm text-center'>
						&copy;{new Date().getFullYear()} Infinite-Apps | All rights reserved
						|{" "}
						<Link
							to='terms-conditions'
							style={{fontWeight: "bold"}}
							onClick={() => {
								window.scrollTo({top: 0, behavior: "smooth"});
							}}
						>
							Terms Of Service
						</Link>{" "}
						|{" "}
						<Link
							to='privacy-policy'
							style={{fontWeight: "bold"}}
							onClick={() => {
								window.scrollTo({top: 0, behavior: "smooth"});
							}}
						>
							Privacy Policy
						</Link>
					</p>
				</div>
			</div>
		</FooterWrapper>
	);
}

export default FooterComp;

const FooterWrapper = styled.footer`
	background-color: var(--background-1);
	padding: 20px 0;
	color: white;

	h4 {
		font-size: 18px;
		font-weight: 700;
		margin-bottom: 20px;
		color: white;
	}

	h5 {
		color: white;
	}

	p {
		margin-bottom: 20px;
		color: white;
	}

	ul {
		list-style: none;
		padding-left: 0;
		margin-bottom: 20px;
	}

	li {
		margin-bottom: 10px;
		color: white;
	}

	a {
		color: #fff;
		text-decoration: none;
	}

	a:hover {
		text-decoration: underline;
	}
`;

/** @format */
import React from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";
import infiniteAppsLogo from "../../images/InfiniteAppsLogo.png";
import {useSidebarContext} from "../../actions_context";
import {signout, isAuthenticated} from "../../auth";
import {createBrowserHistory} from "history";

const Sidebar = () => {
	const {openSidebar, closeSidebar, isSidebarOpen} = useSidebarContext();

	const sidebarContent = () => {
		const history = createBrowserHistory();

		return (
			<SidebarContentWrapper show={isSidebarOpen}>
				<ul>
					<li
						className='mt-4'
						onClick={() => {
							window.scrollTo({top: 0, behavior: "smooth"});
						}}
					>
						<Link
							to='/'
							className='sidebar-link'
							onClick={isSidebarOpen ? closeSidebar : openSidebar}
						>
							<React.Fragment>
								<i className='fa-solid fa-house linkIcons'></i>
								Home
							</React.Fragment>
						</Link>
					</li>

					<li
						onClick={() => {
							window.scrollTo({top: 0, behavior: "smooth"});
						}}
					>
						<Link
							to='/get-quote-now'
							className='sidebar-link'
							onClick={isSidebarOpen ? closeSidebar : openSidebar}
						>
							{isSidebarOpen ? (
								<React.Fragment>
									<i className='fa-solid fa-note-sticky linkIcons'></i>
									Get A Quote
								</React.Fragment>
							) : null}
						</Link>
					</li>

					<li
						onClick={() => {
							window.scrollTo({top: 0, behavior: "smooth"});
						}}
					>
						<Link
							to='/pricing'
							className='sidebar-link'
							onClick={isSidebarOpen ? closeSidebar : openSidebar}
						>
							{isSidebarOpen ? (
								<React.Fragment>
									<i className='fa-solid fa-dollar linkIcons'></i>
									Pricing
								</React.Fragment>
							) : null}
						</Link>
					</li>

					<li
						onClick={() => {
							window.scrollTo({top: 0, behavior: "smooth"});
						}}
					>
						<Link
							to='/promotion'
							className='sidebar-link'
							onClick={isSidebarOpen ? closeSidebar : openSidebar}
						>
							{isSidebarOpen ? (
								<React.Fragment>
									<i class='fa-brands fa-free-code-camp linkIcons'></i>
									90 day free trial
								</React.Fragment>
							) : null}
						</Link>
					</li>

					<li
						onClick={() => {
							window.scrollTo({top: 0, behavior: "smooth"});
						}}
					>
						<Link
							to='/about'
							className='sidebar-link'
							onClick={isSidebarOpen ? closeSidebar : openSidebar}
						>
							{isSidebarOpen ? (
								<React.Fragment>
									<i className='fa-solid fa-address-card linkIcons'></i>
									About Us
								</React.Fragment>
							) : null}
						</Link>
					</li>
					<li
						onClick={() => {
							window.scrollTo({top: 0, behavior: "smooth"});
						}}
					>
						<Link
							to='/contact'
							className='sidebar-link'
							onClick={isSidebarOpen ? closeSidebar : openSidebar}
						>
							{isSidebarOpen ? (
								<React.Fragment>
									<i className='fa-solid fa-address-book linkIcons'></i>
									Contact Us
								</React.Fragment>
							) : null}
						</Link>
					</li>
					{/* <li
						onClick={() => {
							window.scrollTo({ top: 0, behavior: "smooth" });
						}}>
						<Link
							to='/FAQ'
							className='sidebar-link'
							onClick={isSidebarOpen ? closeSidebar : openSidebar}>
							{isSidebarOpen ? (
								<React.Fragment>
									<i className='fa-sharp fa-solid fa-person-circle-question linkIcons'></i>
									FAQ
								</React.Fragment>
							) : null}
						</Link>
					</li> */}
					<li
						onClick={() => {
							window.scrollTo({top: 0, behavior: "smooth"});
						}}
					>
						<Link
							to='/blogs'
							className='sidebar-link'
							onClick={isSidebarOpen ? closeSidebar : openSidebar}
						>
							{isSidebarOpen ? (
								<React.Fragment>
									<i className='fa-solid fa-newspaper linkIcons'></i>
									blogs
								</React.Fragment>
							) : null}
						</Link>
					</li>

					{/* <li
						onClick={() => {
							window.scrollTo({ top: 0, behavior: "smooth" });
						}}>
						<Link
							to='/clients-feedback'
							className='sidebar-link'
							onClick={isSidebarOpen ? closeSidebar : openSidebar}>
							{isSidebarOpen ? <React.Fragment>Feedback</React.Fragment> : null}
						</Link>
					</li> */}

					{isAuthenticated() && isAuthenticated().user.role === 0 && (
						<React.Fragment>
							<li
								className='nav-item  mt-5'
								onClick={() => {
									window.scrollTo({top: 0, behavior: "smooth"});
								}}
							>
								<Link
									style={{fontWeight: "bold"}}
									className='nav-link'
									to='/dashboard'
									onClick={isSidebarOpen ? closeSidebar : openSidebar}
								>
									Business Dashboard
								</Link>
							</li>
						</React.Fragment>
					)}

					{isAuthenticated() && isAuthenticated().user.role === 1 && (
						<React.Fragment>
							<li
								className='nav-item ml-5 mt-3'
								onClick={() => {
									window.scrollTo({top: 0, behavior: "smooth"});
								}}
							>
								<Link
									style={{fontWeight: "bold"}}
									className='nav-link '
									to='/admin/dashboard'
									onClick={isSidebarOpen ? closeSidebar : openSidebar}
								>
									Admin Dashboard
								</Link>
							</li>
						</React.Fragment>
					)}

					{isAuthenticated() && isAuthenticated().user.role === 1000 && (
						<React.Fragment>
							<li
								className='nav-item  mt-5'
								onClick={() => {
									window.scrollTo({top: 0, behavior: "smooth"});
								}}
							>
								<Link
									style={{fontWeight: "bold"}}
									className='nav-link'
									to='/business-dashboard'
									onClick={isSidebarOpen ? closeSidebar : openSidebar}
								>
									Business Dashboard
								</Link>
							</li>
						</React.Fragment>
					)}

					{!isAuthenticated() && (
						<React.Fragment>
							<li
								className='nav-item ml-5 mt-3'
								onClick={() => {
									window.scrollTo({top: 0, behavior: "smooth"});
								}}
							>
								<Link
									className='nav-link '
									to='/login'
									style={{fontWeight: "bold"}}
									onClick={isSidebarOpen ? closeSidebar : openSidebar}
								>
									Login
								</Link>
							</li>
						</React.Fragment>
					)}

					{isAuthenticated() && (
						<li
							className='nav-item'
							onClick={() => {
								window.scrollTo({top: 0, behavior: "smooth"});
							}}
						>
							<span>
								<span
									className='signoutbutton nav-link'
									style={{
										cursor: "pointer",
										margin: 10,
										fontWeight: "bold",
										textDecoration: "underline",
										color: "red",
										fontStyle: "italic",
									}}
									onClick={() =>
										signout(() => {
											history.push("/");
											setTimeout(() => {
												console.log("Signout");
												window.location.reload(false);
											}, 1000);
										})
									}
								>
									Signout
								</span>
							</span>
						</li>
					)}
				</ul>
			</SidebarContentWrapper>
		);
	};

	return (
		<SidebarWrapper>
			<Link to='/'>
				<img src={infiniteAppsLogo} alt='infinite-apps.com' loading='lazy' />
			</Link>
			<i
				className='fa-solid fa-bars burgerMenu'
				onClick={isSidebarOpen ? closeSidebar : openSidebar}
			></i>

			{sidebarContent()}
		</SidebarWrapper>
	);
};

export default Sidebar;

const SidebarWrapper = styled.div`
	position: -webkit-sticky;
	position: sticky;
	/* background: var(--background-1); */
	background: linear-gradient(
		90deg,
		var(--background-0) 20%,
		var(--background-1) 80%
	);
	height: 80px;
	top: 0;
	z-index: 2000;
	overflow-x: hidden;

	.burgerMenu {
		color: white;
		font-size: 25px;
		position: absolute;
		top: 40%;
		left: 90%;
	}

	img {
		width: 180px;
	}

	@media (min-width: 680px) {
		display: none;
	}
`;

const SidebarContentWrapper = styled.div`
	overflow-y: auto;
	position: fixed;
	top: 79px;
	right: 0;
	width: 70%;
	height: 100%;
	background: #f4f5f6;
	z-index: 10000;
	border-right: 3px solid darkgrey;
	transition: 0.5s;
	transform: ${(props) => (props.show ? "translateX(0)" : "translateX(250%)")};

	/*transform: translateX(-100%);*/ /**this will hide the side bar */
	ul {
		list-style-type: none;
		padding: 0 !important;
		text-transform: uppercase;
	}
	.sidebar-link {
		display: block;
		font-size: 1rem;
		text-transform: capitalize;
		color: black;
		padding: 1.1rem 1.1rem;
		background: transparent;
		transition: 0.3s;
		font-weight: bold;
		text-transform: uppercase;
	}
	.sidebar-link:hover {
		background: #727272;
		color: whitesmoke;
		/* padding: 1rem 2rem 1rem 2rem; */
		text-decoration: none;
	}

	.linkIcons {
		font-size: 15px;
		margin-right: 8px;
		color: var(--goldColorMain2);
	}

	@media (min-width: 600px) {
		width: 20rem;
	}
	@media (max-width: 600px) {
		font-size: 0.8rem !important;
		.sidebar-link {
			font-size: 0.8rem !important;
		}
	}
`;

/** @format */

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { SidebarProvider } from "./actions_context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<SidebarProvider>
		<React.StrictMode>
			<App />
		</React.StrictMode>
	</SidebarProvider>,
);

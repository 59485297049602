/** @format */

import React from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";
import infiniteAppsLogo from "../../images/InfiniteAppsLogo.png";
import {isAuthenticated, signout} from "../../auth";
import {createBrowserHistory} from "history";

const isActive = (history, path) => {
	if (history === path) {
		return {
			textDecoration: "none",
			transition: "var(--mainTransition)",
			borderBottom: "2px solid var(--goldColor)",
			borderTop: "2px solid var(--goldColor)",
		};
	} else {
		return {};
	}
};

const Navbar = ({location, setLocation}) => {
	const history = createBrowserHistory();

	return (
		<NavbarWrapper className=' navbar  navbar-expand-sm'>
			<div className='collapse navbar-collapse '>
				<ul className='navbar-nav mx-auto '>
					<Link to='/' onClick={() => setLocation("/")}>
						<img
							width='230'
							height='120'
							src={infiniteAppsLogo}
							alt='infinite-apps.com'
							loading='lazy'
						/>
					</Link>
					<li className='my-auto'>
						{" "}
						<Link
							style={isActive(location, "/")}
							to='/'
							onClick={() => setLocation("/")}
						>
							Home
						</Link>{" "}
					</li>

					<li className='my-auto'>
						{" "}
						<Link
							style={isActive(location, "/pricing")}
							to='/pricing'
							onClick={() => setLocation("/pricing")}
						>
							Pricing
						</Link>{" "}
					</li>
					<li className='my-auto'>
						{" "}
						<Link
							style={isActive(location, "/about")}
							to='/about'
							onClick={() => setLocation("/about")}
						>
							About
						</Link>{" "}
					</li>
					<li className='my-auto'>
						{" "}
						<Link
							style={isActive(location, "/blogs")}
							to='/blogs'
							onClick={() => setLocation("/blogs")}
						>
							Blog
						</Link>{" "}
					</li>
					<li className='my-auto'>
						{" "}
						<Link
							style={isActive(location, "/contact")}
							to='/contact'
							onClick={() => setLocation("/contact")}
						>
							Contact
						</Link>{" "}
					</li>
					<li className='my-auto'>
						{" "}
						<Link
							style={isActive(location, "/promotion")}
							to='/promotion'
							onClick={() => setLocation("/promotion")}
						>
							<strong>Free Trial!</strong>
						</Link>{" "}
					</li>
					{isAuthenticated() && isAuthenticated().user ? (
						<li className='my-auto ml-5' style={{fontWeight: "bold"}}>
							{" "}
							<Link
								style={isActive(location, "/business-dashboard")}
								to='/business-dashboard'
								onClick={() => setLocation("/business-dashboard")}
							>
								Business Dashboard
							</Link>{" "}
						</li>
					) : null}

					{!isAuthenticated() && !isAuthenticated().user ? (
						<li className='my-auto ml-5' style={{fontWeight: "bold"}}>
							{" "}
							<Link
								style={isActive(location, "/login")}
								to='/login'
								onClick={() => setLocation("/login")}
							>
								Login
							</Link>{" "}
						</li>
					) : null}
					{isAuthenticated() && (
						<li
							className='my-auto ml-2'
							style={{fontWeight: "bold", color: "#ffc4c4"}}
						>
							{" "}
							<span
								onClick={() =>
									signout(() => {
										history.push("/");
										setTimeout(() => {
											console.log("Signout");
											window.location.reload(false);
										}, 1000);
									})
								}
							>
								Signout
							</span>{" "}
						</li>
					)}

					{/* <li className='my-auto'>
						{" "}
						<Link
							style={isActive(location, "/register")}
							to='/register'
							onClick={() => setLocation("/register")}>
							Register
						</Link>{" "}
					</li> */}
				</ul>
			</div>
		</NavbarWrapper>
	);
};

export default Navbar;

const NavbarWrapper = styled.nav`
	position: -webkit-sticky;
	position: sticky;
	/* background: var(--background-1); */
	background: linear-gradient(
		90deg,
		var(--background-0) 15%,
		var(--background-1) 70%
	);
	height: 120px;
	top: 0;
	z-index: 2000;

	li a {
		color: white;
		font-size: 1rem;
		text-transform: uppercase;
		padding: 10px;
		transition: var(--mainTransition);
	}

	li span {
		color: #ffc4c4;
		font-size: 1rem;
		text-transform: uppercase;
		padding: 10px;
		transition: var(--mainTransition);
		cursor: pointer;
	}

	.nav-link {
		color: white;
	}

	li a:hover {
		text-decoration: none;
		transition: var(--mainTransition);
		border-bottom: 2px solid var(--goldColorMain);
		border-top: 2px solid var(--goldColorMain);
	}

	img {
		margin-right: 200px;
	}

	@media (max-width: 900px) {
		li a {
			font-size: 0.7rem;
			margin: 0px;
		}
		li {
			margin: 0px 0px 0px 0px;
		}

		img {
			margin-right: 10px;
			width: 75%;
		}
	}

	@media (max-width: 680px) {
		display: none;
	}
`;

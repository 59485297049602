/** @format */

import React from "react";
import { useSidebarContext } from "../../actions_context";

const DarkBackground = () => {
	const { openSidebar, closeSidebar, isSidebarOpen } = useSidebarContext();

	return (
		<div
			className='DarkbackgroundForSidebar'
			onClick={isSidebarOpen ? closeSidebar : openSidebar}></div>
	);
};

export default DarkBackground;

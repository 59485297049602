/** @format */

import React, { useContext, useReducer } from "react";
import reducer from "./actions_reducer";
import { SIDEBAR_OPEN, SIDEBAR_CLOSE } from "./actions";

const initialState = {
	isSidebarOpen: false,
};

const SidebarContext = React.createContext();

export const SidebarProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const openSidebar = () => {
		dispatch({ type: SIDEBAR_OPEN });
	};
	const closeSidebar = () => {
		dispatch({ type: SIDEBAR_CLOSE });
	};

	return (
		<SidebarContext.Provider
			value={{
				...state,
				closeSidebar,
				openSidebar,
			}}>
			{children}
		</SidebarContext.Provider>
	);
};

// make sure use
export const useSidebarContext = () => {
	return useContext(SidebarContext);
};

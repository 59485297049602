/** @format */
import React, {lazy, Suspense, useEffect, useState} from "react";
import "./App.css";
import {Route, BrowserRouter, Routes} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga4";
import Navbar from "./components/Navbar/Navbar";
import Sidebar from "./components/Sidebar/Sidebar";
import Footer from "./components/FooterComp";
import DarkBackground from "./components/Navbar/DarkBackground";
import {useSidebarContext} from "./actions_context";
import {Spin} from "antd";

// Lazy Loaded Pages
const Home = lazy(() => import("./pages/home/Home"));
const Login = lazy(() => import("./pages/login/Login"));
const Register = lazy(() => import("./pages/register/Register"));
const About = lazy(() => import("./pages/about/Aboutus"));
const PricingPage = lazy(() => import("./pages/pricing/PricingPage"));
const Blogs = lazy(() => import("./pages/blogs/Blogs"));
const SinglePricingItem = lazy(() =>
	import("./pages/pricing/SinglePricingItem")
);
const BuildApp = lazy(() => import("./pages/application_builder/BuildApp"));
const SingleBuilderItem = lazy(() =>
	import("./pages/application_builder/SingleBuilderItem")
);
const BusinessDashboard = lazy(() => import("./pages/user/BusinessDashboard"));
const BuyingDomain = lazy(() => import("./pages/user/BuyingDomain"));
const UpdateClientProfile = lazy(() =>
	import("./pages/user/UpdateClientProfile")
);
const Tickets = lazy(() => import("./pages/user/Tickets"));
const AddingFeedback = lazy(() => import("./pages/user/AddingFeedback"));
const BillingPage = lazy(() => import("./pages/user/BillingPage"));
const Blog1 = lazy(() => import("./pages/blogs/Blog1"));
const Blog2 = lazy(() => import("./pages/blogs/Blog2"));
const Blog3 = lazy(() => import("./pages/blogs/Blog3"));
const Blog4 = lazy(() => import("./pages/blogs/blog4"));
const Blog5 = lazy(() => import("./pages/blogs/blog5"));
const Blog6 = lazy(() => import("./pages/blogs/blog6"));
const Blog7 = lazy(() => import("./pages/blogs/blog7"));
const Blog8 = lazy(() => import("./pages/blogs/blog8"));
const Blog9 = lazy(() => import("./pages/blogs/blog9"));
const Blog10 = lazy(() => import("./pages/blogs/blog10"));
const Blog11 = lazy(() => import("./pages/blogs/blog11"));
const Blog12 = lazy(() => import("./pages/blogs/Blog12"));
const Blog13 = lazy(() => import("./pages/blogs/blog13"));
const Blog14 = lazy(() => import("./pages/blogs/blog14"));
const NotFound = lazy(() => import("./pages/NotFound"));
const PrivacyPolicy = lazy(() =>
	import("./pages/privacy-policy/PrivacyPolicy")
);
const TermsConditions = lazy(() =>
	import("./pages/privacy-policy/TermsConditions")
);
const Contact = lazy(() => import("./pages/contactUs/Contact"));
const AdminDashboard = lazy(() => import("./Admin/AdminDashboard"));
const AddAppointmentHours = lazy(() => import("./Admin/AddAppointmentHours"));
const SingleMeetingPage = lazy(() => import("./Admin/SingleMeetingPage"));
const AddingOffers = lazy(() => import("./Admin/AddingOffers"));
const AddingOffersToClient = lazy(() => import("./Admin/AddingOffersToClient"));
const UpdateOffers = lazy(() => import("./Admin/UpdateOffers"));
const ManageTickets = lazy(() => import("./Admin/ManageTickets"));
const SubscriptionsOverview = lazy(() =>
	import("./Admin/SubscriptionsOverview")
);
const EmailSender = lazy(() => import("./Admin/EmailSender"));

const AddList = lazy(() => import("./Admin/AddList"));
const AddBlogs = lazy(() => import("./Admin/AddBlogs"));
const UpdateBlogs = lazy(() => import("./Admin/UpdateBlogs"));
const Blog15 = lazy(() => import("./pages/blogs/blog15"));
const Blog16 = lazy(() => import("./pages/blogs/blog16"));
const SchedulingSoftwareItems = lazy(() =>
	import("./pages/pricing/SchedulingSystem/SchedulingSoftwareItems")
);
const SingleSchedulingSoftware = lazy(() =>
	import("./pages/pricing/SchedulingSystem/SingleSchedulingSoftware")
);
const SchedulingSoftwareItemsBuilder = lazy(() =>
	import(
		"./pages/application_builder/schedulingBuilder/SchedulingSoftwareItems"
	)
);
const SingleSchedulingBuilder = lazy(() =>
	import(
		"./pages/application_builder/schedulingBuilder/SingleSchedulingBuilder"
	)
);
const Blog17 = lazy(() => import("./pages/blogs/blog17"));
const Blog18 = lazy(() => import("./pages/blogs/blog18"));
const Blog19 = lazy(() => import("./pages/blogs/blog19"));
const Blog20 = lazy(() => import("./pages/blogs/blog20"));
const Blog21 = lazy(() => import("./pages/blogs/Blog21"));
const Blog22 = lazy(() => import("./pages/blogs/Blog22"));
const Blog23 = lazy(() => import("./pages/blogs/Blog23"));

const SalonsPromotion = lazy(() =>
	import("./pages/PromotionPage/SalonsPromotion")
);

const App = () => {
	const [location, setLocation] = useState("");

	const {isSidebarOpen} = useSidebarContext();

	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENTID);
		ReactGA.gtag("event", "page_view", {
			page_path: window.location.pathname,
		});
	}, [location]);

	return (
		<BrowserRouter>
			<ToastContainer />
			<Navbar location={location} setLocation={setLocation} />
			{isSidebarOpen ? <DarkBackground /> : null}
			<Sidebar />
			<Routes>
				<Route
					path='/'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<Home path='/' setLocation={setLocation} />
						</Suspense>
					}
				/>

				<Route
					path='/login'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<Login path='/' setLocation={setLocation} />
						</Suspense>
					}
				/>
				<Route path='/register' exact element={<Register />} />
				<Route
					path='/about'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<About />
						</Suspense>
					}
					setLocation={setLocation}
				/>
				<Route
					path='/promotion'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<SalonsPromotion />
						</Suspense>
					}
					setLocation={setLocation}
				/>
				<Route
					path='/privacy-policy'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<PrivacyPolicy setLocation={setLocation} />
						</Suspense>
					}
				/>
				<Route
					path='/terms-conditions'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<TermsConditions setLocation={setLocation} />
						</Suspense>
					}
				/>

				<Route
					path='/pricing'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<PricingPage path='/pricing' setLocation={setLocation} />
						</Suspense>
					}
				/>
				<Route
					path='/contact'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<Contact path='/contact' setLocation={setLocation} />
						</Suspense>
					}
				/>
				<Route
					path='/scheduling-software'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<SchedulingSoftwareItems
								path='/pricing'
								setLocation={setLocation}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/scheduling-software/:website'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<SingleSchedulingSoftware
								path='/pricing'
								setLocation={setLocation}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/pricing/:website'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<SinglePricingItem path='/pricing' setLocation={setLocation} />
						</Suspense>
					}
				/>
				<Route
					path='/scheduling-software-builder'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<SchedulingSoftwareItemsBuilder
								path='/pricing'
								setLocation={setLocation}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/application-builder'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<BuildApp path='/application-builder' setLocation={setLocation} />
						</Suspense>
					}
				/>
				<Route
					path='/get-quote-now'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<BuildApp path='/get-quote-now' setLocation={setLocation} />
						</Suspense>
					}
				/>

				<Route
					path='/application-builder/:website'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<SingleBuilderItem
								path='/application-builder'
								setLocation={setLocation}
							/>
						</Suspense>
					}
				/>

				<Route
					path='/scheduling-software-builder/:website'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<SingleSchedulingBuilder
								path='/application-builder'
								setLocation={setLocation}
							/>
						</Suspense>
					}
				/>

				<Route
					path='/blogs'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<Blogs setLocation={setLocation} />
						</Suspense>
					}
				/>

				<Route
					path='/blogs/Why-a-Professionally-Designed-Website-is-Essential-for-Business-Growth-in-2023'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<Blog1 setLocation={setLocation} />
						</Suspense>
					}
				/>

				<Route
					path='/blogs/The-Importance-of-Mobile-Optimization-for-Your-Website-in-2023'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<Blog2 setLocation={setLocation} />
						</Suspense>
					}
				/>

				<Route
					path='/blogs/How-to-Create-a-Website-That-Converts-Visitors-into-Customers'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<Blog3 setLocation={setLocation} />
						</Suspense>
					}
				/>

				<Route
					path='/blogs/the-top-5-web-development-trends-in-2023'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<Blog4 setLocation={setLocation} />
						</Suspense>
					}
				/>

				<Route
					path='/blogs/The-Benefits-of-Cloud-Computing-for-Small-Businesses-in-2023'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<Blog5 setLocation={setLocation} />
						</Suspense>
					}
				/>

				<Route
					path='/blogs/10-social-media-strategies-for-growing-your-business'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<Blog6 setLocation={setLocation} />
						</Suspense>
					}
				/>

				<Route
					path='/blogs/The-Importance-of-Cybersecurity-in-Todays-Digital-World'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<Blog7 setLocation={setLocation} />
						</Suspense>
					}
				/>

				<Route
					path='/blogs/5-Ways-to-Improve-Your-Online-Reputation-in-2023'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<Blog8 setLocation={setLocation} />
						</Suspense>
					}
				/>

				<Route
					path='/blogs/The-Power-of-Influencer-Marketing'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<Blog9 setLocation={setLocation} />
						</Suspense>
					}
				/>

				<Route
					path='/blogs/10-Tips-for-Effective-Time-Management-in-the-Workplace'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<Blog10 setLocation={setLocation} />
						</Suspense>
					}
				/>

				<Route
					path='/blogs/how-much-does-a-website-cost'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<Blog11 setLocation={setLocation} />
						</Suspense>
					}
				/>

				<Route
					path='/blogs/Should-You-Hire-a-Web-Designer-or-Do-It-Yourself'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<Blog12 setLocation={setLocation} />
						</Suspense>
					}
				/>

				<Route
					path='/blogs/web-designer-vs-do-it-yourself'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<Blog12 setLocation={setLocation} />
						</Suspense>
					}
				/>

				<Route
					path='/blogs/Custom-Web-Designs-vs.-Templates-Which-One-is-Better-for-Your-Website'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<Blog13 setLocation={setLocation} />
						</Suspense>
					}
				/>

				<Route
					path='/blogs/the-difference-between-static-and-dynamic-websites'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<Blog14 setLocation={setLocation} />
						</Suspense>
					}
				/>

				<Route
					path='/blogs/booking-websites-for-salons'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<Blog15 setLocation={setLocation} />
						</Suspense>
					}
				/>

				<Route
					path='/blogs/how-much-does-an-ecommerce-website-cost'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<Blog16 setLocation={setLocation} />
						</Suspense>
					}
				/>

				<Route
					path='/blogs/why-every-hair-salon-needs-a-booking-system'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<Blog17 setLocation={setLocation} />
						</Suspense>
					}
				/>

				<Route
					path='/blogs/why-invest-booking-system-grooming-salon'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<Blog18 setLocation={setLocation} />
						</Suspense>
					}
				/>

				<Route
					path='/blogs/why-it-is-important-to-have-a-booking-system-for-your-barbershop'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<Blog19 setLocation={setLocation} />
						</Suspense>
					}
				/>

				<Route
					path='/blogs/how-a-booking-system-for-your-barbershop-will-save-you-money-and-time'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<Blog20 setLocation={setLocation} />
						</Suspense>
					}
				/>

				<Route
					path='/blogs/10-key-benefits-of-an-online-booking-system-for-your-barber-shop'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<Blog21 setLocation={setLocation} />
						</Suspense>
					}
				/>

				<Route
					path='/blogs/how-to-improve-your-salon-efficiency-with-digital-scheduling-system'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<Blog22 setLocation={setLocation} />
						</Suspense>
					}
				/>

				<Route
					path='/blogs/choosing-the-best-online-booking-system-for-your-beauty-salon'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<Blog23 setLocation={setLocation} />
						</Suspense>
					}
				/>

				<Route
					path='/business-dashboard'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<BusinessDashboard setLocation={setLocation} />
						</Suspense>
					}
				/>

				<Route
					path='/business-dashboard/buying-domain'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<BuyingDomain setLocation={setLocation} />
						</Suspense>
					}
				/>

				<Route
					path='/client/ticketing-system'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<Tickets setLocation={setLocation} />
						</Suspense>
					}
				/>

				<Route
					path='/client/update-profile/:userId'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<UpdateClientProfile />
						</Suspense>
					}
				/>

				<Route
					path='/client/add-comment'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<AddingFeedback setLocation={setLocation} />
						</Suspense>
					}
				/>

				<Route
					path='/client/billing-management'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<BillingPage />
						</Suspense>
					}
				/>

				<Route
					path='/admin/dashboard'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<AdminDashboard />
						</Suspense>
					}
				/>

				<Route
					path='/admin/appointment-hours'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<AddAppointmentHours />
						</Suspense>
					}
				/>

				<Route
					path='/admin/single-meeting-details/:meetingById'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<SingleMeetingPage />
						</Suspense>
					}
				/>

				<Route
					path='/admin/adding-offers'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<AddingOffers />
						</Suspense>
					}
				/>

				<Route
					path='/admin/update-user-account/:userId'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<AddingOffersToClient />
						</Suspense>
					}
				/>

				<Route
					path='/admin/update-offers/:offerId'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<UpdateOffers />
						</Suspense>
					}
				/>

				<Route
					path='/admin/manage-tickets'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<ManageTickets />
						</Suspense>
					}
				/>

				<Route
					path='/admin/subscriptions-billing'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<SubscriptionsOverview />
						</Suspense>
					}
				/>

				<Route
					path='/admin/sms-sender'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<EmailSender />
						</Suspense>
					}
				/>

				<Route
					path='/admin/adding-list'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<AddList />
						</Suspense>
					}
				/>

				<Route
					path='/admin/add-blogs'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<AddBlogs />
						</Suspense>
					}
				/>

				<Route
					path='/admin/update-blogs/:blogId'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<UpdateBlogs />
						</Suspense>
					}
				/>

				<Route
					path='*'
					exact
					element={
						<Suspense
							fallback={<Spin className='spinning-loader' size='large' />}
						>
							<NotFound setLocation={setLocation} />
						</Suspense>
					}
				/>
			</Routes>
			<Footer />
		</BrowserRouter>
	);
};

export default App;
